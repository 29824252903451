<template>
  <div v-if="variants?.length > 1" class="v-ausfuherung-selector">
    <template v-for="variantValue in variants" :key="variantValue">
      <ProductVariant :productKey="'main'" :variantName="VARIANT_NAMES['Ausfuehrung']" :variantValue="variantValue" theme="light" showPreviewImageSkeleton />
    </template>
  </div>
</template>

<script setup lang="ts">
import { VARIANT_NAMES } from '@/js/vue/shopify/keys';
import ProductVariant from '@/js/vue/components/Configurator/ProductVariant.vue';
import { useConfiguratorStore } from '@/js/vue/shopify/stores/configurator';
import { computed } from 'vue';

const store = useConfiguratorStore();

const variants = computed(() => store.products.main.productOptions[VARIANT_NAMES['Ausfuehrung']]);
</script>

<style lang="scss" scoped>
.v-ausfuherung-selector {
  display: flex;
  gap: 15px;
}
</style>
