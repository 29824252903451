<template>
  <div class="v-cart" :class="{ open: cart.sidebarOpened }">
    <div class="v-cart-header">
      <span>{{ labels.title }}</span>
      <span class="v-close-button" @click="cart.hideSidebar">×</span>
    </div>

    <div class="v-line-items">
      <div v-if="!cart.lineItems.length" class="v-line-item">
        <span v-html="labels.empty_cart?.replace(/\r\n|\r|\n/g, '<br />')"></span>
      </div>
      <div class="v-line-item" v-for="line in cart.lineItems">
        <img :src="line.node.merchandise.image?.url ?? line.node.merchandise.product.featuredImage?.url" />
        <div class="v-line-item-content">
          <div class="v-product-title">{{ line.node.merchandise.product.title }}</div>
          <div class="v-variant-title">{{ line.node.merchandise.title }}</div>
          <div class="v-line-item-cost">{{ line.node.cost.totalAmount.currencyCode }} {{ parseFloat(line.node.cost.totalAmount.amount ?? '').toFixed(2) }}</div>
        </div>
      </div>
    </div>

    <div class="v-cart__bottom">
      <div class="v-cart-total">
        <span>{{ labels.subtotal }}:</span>
        <span>CHF {{ parseFloat(cart.cartQuery.result?.cart?.cost.totalAmount.amount ?? '0').toFixed(2) }}</span>
      </div>
      <div class="v-delivery-hint" v-html="labels.subtotal_notice"></div>
      <a
        class="button button--primary"
        :disabled="cart.isEmpty ? 'disabled' : undefined"
        :href="cart.isEmpty ? '' : cart.cartQuery.result?.cart.checkoutUrl"
        @click.passive="handleGotoCheckoutClick"
      >
        <span>{{ labels.to_checkout }}</span>
      </a>
    </div>
  </div>
  <div v-if="cart.sidebarOpened" class="v-cart-offside-clickarea" @click="handleOutsideClick"></div>
</template>

<script lang="ts" setup>
import { useCartStore } from '@/js/vue/shopify/stores/cart';
import { computed, onUnmounted } from 'vue';
import { logEvent } from '@/js/plugins/analytics';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const cart = useCartStore();
const settings = useSettingsStore();
const labels = computed(() => settings.labels.cart);

const escHandler = (e: KeyboardEvent) => {
  if (cart.sidebarOpened && e.key === 'Escape') {
    cart.hideSidebar();
  }
};

const handleOutsideClick = (e: MouseEvent) => {
  cart.hideSidebar();
};

const handleGotoCheckoutClick = () => {
  logEvent('gotoCheckoutClicked', {
    totalPrice: parseFloat(cart.cartQuery.result?.cart?.cost.totalAmount.amount ?? '0'),
  });
};

window.addEventListener('keydown', escHandler);

onUnmounted(() => {
  window.removeEventListener('keydown', escHandler);
});
</script>

<style lang="scss" scoped>
.v-cart-offside-clickarea {
  position: fixed;
  pointer-events: all;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($black, 0.5);
  z-index: 111;
  cursor: pointer;
}

.v-cart {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 340px;
  background: white;
  z-index: 1000;
  padding: 20px;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  line-height: 1.35;
  gap: 20px;

  margin-right: -360px;
  transition: margin-right 250ms cubic-bezier(0.165, 0.84, 0.44, 1);

  &.open {
    margin-right: 0;
  }
}

.v-cart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 10px;
}

.v-close-button {
  font-size: 50px;
  font-weight: 200;
  line-height: 18px;
  margin-top: -10px;
  cursor: pointer;
}

.v-line-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
  overflow: auto;
  margin-right: -20px;
  padding-right: 20px;
  scrollbar-color: rgba($wbrown, 0.2) $wbeige;
  scrollbar-width: thin;
}

.v-line-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;

  > img {
    width: 65px;
    height: auto;
    object-fit: contain;
  }
}

.v-line-item-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.v-variant-title {
  color: #444;
  font-size: 0.9em;
}

.v-cart__bottom {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}

.v-cart-total {
  display: flex;
  justify-content: space-between;
}

.v-delivery-hint {
  font-size: 0.7em;
  font-weight: 300;
  text-align: center;
  line-height: 1.15;
}

.v-line-item-cost {
  margin-top: 10px;
  font-weight: 500;
  text-align: right;
  font-size: 1.1em;
}
</style>
