<template>
  <a
    v-if="labels"
    class="v-configure-now-button"
    :class="{ highlighted: !href.startsWith('#') }"
    @click.passive="onClick"
    :href="href?.replace(/\/$/, '') + (productAusfuehrung ? `?ausfuehrung=${productAusfuehrung}` : '')"
  >
    <div class="v-button-text">{{ labels.product_header_button_text }}</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="12" transform="rotate(90 12 12)" fill="#F5F2EF" />
      <path d="M8 10L12 14L16 10" stroke="#484541" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </a>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { logEvent } from '@/js/plugins/analytics';
import { useSettingsStore } from '@/js/vue/shopify/stores/settings';

const props = defineProps({
  href: {
    type: String,
    required: true,
  },
  productAusfuehrung: {
    type: String,
  },
});

const onClick = () => {
  logEvent('configureNowButtonClicked', {
    href: props.href,
    productAusfuehrung: props.productAusfuehrung,
  });
};

const settings = useSettingsStore();
const labels = computed(() => settings.labels.configurator);
</script>

<style lang="scss" scoped>
.v-configure-now-button {
  display: inline-flex;
  gap: 15px;
  background: $white;
  color: $wbrown-dark;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.11);
  padding: 15px;
  border-radius: 100px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
  @include media(S) {
    font-size: 14px;
    padding: 10px;
  }

  &.highlighted {
    background: $blue-light;
    color: $white;

    svg {
      transform: rotate(-90deg);
    }
  }

  &,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}
</style>
